<template>
    <div class="l_content">
        <div class="title">로그인</div>
        <div class="lc_wrap">
            <div class="id"><input placeholder="아이디 입력" v-model="id" @input="errMsg=''"></input></div>
            <div class="pwd"><input placeholder="비밀번호 입력" id="pwd" type="password" v-model="pw" @input="errMsg=''" @keypress.enter="login"></input></div>
        </div>
        <div class="l_caption">
<!--            <div>아이디가 존재하지 않습니다</div>-->
<!--            <div>비밀번호가 일치하지 않습니다</div>-->
            <div>{{this.errMsg}}</div>
        </div>
        <div class="b_content">
            <button @click="login" class="login_btn">로그인</button>
            <button @click="movePage('/auth/regist')" class="join_btn">회원가입</button>
        </div>
        <div class="find">
            <span @click="movePage('/auth/findid')" class="f_id">아이디찾기</span>
            <span @click="movePage('/auth/findpw')" class="f_pw">비밀번호찾기</span>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    // @ is an alias to /src
    import VuxAlert from "@/mixins/vux-alert"
    import {loginTokenSet} from "@/mixins/Auth";

    export default {
        name: 'LoginComponent',
        mixins: [VuxAlert],
        components: {},
        props: {},
        data() {
            return {
                id: '',
                pw: '',
                redirect: '/',
                errMsg: '',

            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.redirect = (this.$route.query.redirect) ? this.$route.query.redirect : '/';
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            login() {
                if(this.id === '' ) {
                    this.errMsg = '아이디를 입력해주세요.';
                    return;
                }
                if(this.pw === '') {
                    this.errMsg = '바밀번호를 입력해주세요.';
                    return;
                }
                let params = {
                    log_type: 2,
                    mb_id: this.id,
                    mb_password: this.pw
                }
                try {
                    this.$api.$auth.login(params).then(res => res.Data.Result).then(res => {
                        let type = this.returnLoginTypeAlertTxt(res.Info.type);
                        if (type === true) {
                            loginTokenSet(res.List);
                            this.$router.push(this.redirect);
                        } else {
                            // this.errorAlert(type)
                            this.errMsg = type
                        }

                    })
                } catch (e) {
                    console.log(e);
                }
            },
            movePage(url) {
                this.$router.push(url);

            },
            returnLoginTypeAlertTxt(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -3:
                        return '아이디가 존재하지 않습니다';
                    case -4:
                        return '탈퇴회원 입니다';
                    case -5:
                        return '비밀번호를 입력해 주세요';
                    case -6:
                        return '비밀번호가 일치하지 않습니다';
                    default:
                        return '로그인에 실패하였습니다.'
                }
            },
        },
        watch: {},
    }
</script>
