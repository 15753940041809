<template>
    <div class="mobile">
        <div class="cpc_text">정보수정을 원하시면 비밀번호를 입력하세요</div>
        <input type="password"></input>
        <div class="message pt10">비밀번호가 일치하지 않습니다</div>
        <button class="full_btn cpc_btn">확인</button>
    </div>
</template>

<script>
    import VuxAlert from "@/mixins/vux-alert";
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: "CheckPwComponent",
        mixins: [VuxAlert],
        components: {},
        props: {},
        data() {
            return {

            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {

        },
        watch: {},
    }
</script>

<style scoped>

</style>