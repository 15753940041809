<template>
    <component :is="component" v-if="component"  />
</template>

<script>
    import alarmUtil from "@/components/alarm/alarmUtil"
    import VuxAlert from "@/mixins/vux-alert";
    import {mapState} from "vuex";

    export default {
        name: "AlarmComponent",
        mixins: [alarmUtil,VuxAlert ],
        provide() {
            return {
                alarmDelete: this.alarmDelete,
            }
        },
        props: {
            alarmType: {
                type: String,
            },
        },
        data() {
            return {
                alarmLayout: '',
                directType: '',
                component: null,
            }
        },
        created() {
            this.initConfig();
        },
        computed: {
            ...mapState({
                alarmList: state => state.alarm.list,
            }),
            loader() {
                if (!this.alarmLayout) {
                    return null
                }
                return () => import(`@/template/alarm/${this.alarmLayout}`)
            },
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/alarm/AlarmListLayout')
                })
        },
        methods: {
            initConfig() {
                let alarmConfig = this.returnAlarmConfig(this.alarmType);
                this.alarmLayout = alarmConfig.alarmLayout;
                this.directType = alarmConfig.directType;
            },
            alarmDelete(psl_idx=null) {
                let parmas = {}
                if(psl_idx != null) {
                    parmas.psl_idx = [psl_idx];
                }
                try {
                    this.$api.$alarm.deleteAlarm(parmas).then(res => res.Data.Result).then(res => {
                        let type = this.returnAlarmDeleteType(res.Info.type);
                        if(type != true) {
                            this.errorAlert(type);
                            return false;
                        }
                        this.deleteAlarmAfter(psl_idx);
                        this.createAlert({
                            title: '',
                            content: '삭제되었습니다',
                            btnText: '확인',
                            hide: () => {
                                if(this.directType === 'back') {
                                    this.$root.$children[0].hasBack = true;
                                    this.$router.back();
                                }
                            }
                        })
                    });
                }catch (e) {
                    console.log(e);
                }
            },
            returnAlarmDeleteType(type) {
                switch (type) {
                    case 1:
                        return true;
                    default:
                        return "알람 삭제에 실패하였습니다. 잠시후 다시 시도해주세요."
                }
            },
            deleteAlarmAfter(psl_idx=null) {
                if(psl_idx != null) {
                    let alarmIndex = this.alarmList.findIndex(alarm => alarm.psl_idx === psl_idx);
                    if(alarmIndex > -1) {
                        this.$store.dispatch('alarm/popList', {data: alarmIndex});
                    }
                }else{
                    this.$store.dispatch('alarm/initList', {});
                }
            },
        },
    }
</script>

<style scoped>

</style>