const configAuth={
    id:{
        authKey :'mb_id',
    }

}
export default {
    methods: {
        returnAuthConfig(type) {
            return configAuth[type];
        }
    }
}