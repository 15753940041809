<template>
    <div class="j_content step1">
        <div class="jc_header">
            회원가입
        </div>
        <div class="jc_wrap">
            <div class="title">이용약관동의 및 가입유형 선택</div>
            <div class="form">
                <div class="term">
                    <div class="title">개인정보 취급방침</div>
                    <terms-component :termsType="'privacy'" class="context"></terms-component>

                    <div class="checks">
                        <input type="checkbox" name="privacy" id="privacy"  v-model="privacyAgree" :value="true" @change="noCheckTerm=false"></input>
                        <label for="privacy">개인정보 취급방침에 동의합니다 (필수)</label>
                    </div>
                </div>
                <div class="term stipulation">
                    <div class="title">서비스 이용약관</div>
                    <terms-component :termsType="'stipulation'" class="context"></terms-component>

                    <div class="checks">
                        <input type="checkbox" name="stipulation" id="stipulation"  v-model="stipulationAgree" :value="true" @change="noCheckTerm=false"></input>
                        <label for="stipulation">서비스 이용약관 내용에 동의합니다 (필수)</label>
                    </div>
                </div>
            </div>
            <div class="type">
                <button @click="checkValidator(1)" class="mentor">
                    <div class="title">멘토 회원가입</div>
                    <div class="text">
                        멘티들의 이야기에<br>
                        진심어린 답변으로<br>
                        조언과 응원을 해주세요
                    </div>
                </button>

                <button @click="checkValidator(2)" class="mentee">
                    <div class="title">멘티 회원가입</div>
                    <div class="text">
                        꿈/고민/상담 등 자유롭게<br>
                        멘토에게 질문하고 조언을<br>
                        받을 수 있어요

                    </div>
                </button>
            </div>
            <div class="j_caption" v-if="noCheckTerm">필수약관에 동의하지 않으면 회원가입이 불가능합니다</div>
        </div>

    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert"
    import TermsComponent from "@/components/terms/TermsComponent";
    import {mapState} from 'vuex';

    export default {
        name: "RegistTermsCheckComponent",
        mixins: [VuxAlert],
        components: {
            TermsComponent
        },
        props: {
            setData: {
                type: Function,
                default: () => {}
            },
            changeStep: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                noCheckTerm: false,
                privacyAgree: false,
                stipulationAgree: false,
                runChange: true,
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            checkValidator(type) {
                if(!this.stipulationAgree || !this.privacyAgree) {
                    this.noCheckTerm = true;
                    return;
                }
                this.selectType(type);
            },

            selectType(value) {
                this.type = value;
                this.setData('type', value);
                this.changeStep(2);
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>