<template>
    <div class="j_content step3 mentor">
        <div class="jc_header">
            회원가입
        </div>
        <div class="info">
            <div class="title-wrap">
                <div class="title">정보입력</div>
                <div class="text">
                    모든 항목을 빠짐없이 기입해주세요. <br>
                    아이디, 이름, 생년월일 항목은 추후 수정이 불가능합니다
                </div>
            </div>
            <div class="form">
                <div class="form-data file">
                    <div class="title">프로필<br>이미지</div>
                    <div class="value-wrap">
                        <!--<label for="file" class="no-image"> &lt;!&ndash;파일이없다면 class="no-image" 추가 &ndash;&gt;
                            &lt;!&ndash;<img src="@/assets/image/rebuild/popup_img.jpg">&ndash;&gt; &lt;!&ndash;파일이 있다면 img태그로 이미지 여기에&ndash;&gt;
                        </label>-->
                        <el-upload
                                class="avatar-uploader border-radius-100 size88"
                                action=""
                                list-type="picture-card"
                                :show-file-list="false"
                                :file-list="files"
                                :auto-upload="false"
                                :on-change="onlyOnePhotoChange"
                        >
                            <label :class="{'no-image' : !hasImage()}"> <!--파일이없다면 class="no-image" 추가 -->
                                <img v-if="hasImage()" :src="files[0].url">
                                <!--<img src="@/assets/image/rebuild/popup_img.jpg">--> <!--파일이 있다면 img태그로 이미지 여기에-->
                            </label>
                        </el-upload>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('files')">{{validation.firstError('files')}}</div>
                </div>
                <div class="form-data">
                    <div class="title">아이디</div>
                    <div class="input check">
                        <input type="text" class="value" placeholder="영어,숫자 4~12자" v-model="id" @input="parentSetData('id', id)" maxLength="12"></input>
                        <button class="send" @click="checkIdOverLap" v-if="hasIdCheck === false">중복확인</button>
                        <span class="chk_ok" v-else></span>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('id')">{{validation.firstError('id')}}</div>
                    <div class="form-caption" v-else-if="idOverlap">중복된 아이디입니다.</div>
                    <div class="form-caption" v-else-if="noIdCheck">아이디 중복확인을 진행해주세요.</div>
                </div>
                <div class="form-data">
                    <div class="title">이름</div>
                    <div class="input">
                        <input type="text" class="value" placeholder="이름입력" v-model="name" @input="parentSetData('name', name)" maxLength="5"></input>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('name')" v-html="validation.firstError('name')"></div>
                </div>
                <div class="form-data">
                    <div class="title">이메일</div>
                    <div class="input">
                        <input type="text" class="value" placeholder="이메일입력" v-model="email" @input="parentSetData('email', email)" maxLength="30"></input>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('email')" v-html="validation.firstError('email')"></div>
                </div>
                <div class="form-data birth">
                    <div class="title">생년월일</div>
                    <!--<el-select class="select year" placeholder="년도">
                        <el-option>년도</el-option>
                    </el-select>
                    <el-select class="select" placeholder="월">
                        <el-option>월</el-option>
                    </el-select>
                    <el-select class="select" placeholder="일">
                        <el-option>일</el-option>
                    </el-select>-->
                    <el-date-picker
                            class="select"
                            v-model="birth"
                            type="date"
                            placeholder="생년월일"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            :clearable="false"
                            :prefix-icon="'d'"
                            @change="parentSetData('birth', birth)"
                    >
                    </el-date-picker>
                    <div class="form-caption" v-if="validation.hasError('birth')" v-html="validation.firstError('birth')"></div>
                </div>
                <div class="form-data">
                    <div class="title">비밀번호</div>
                    <div class="input">
                        <input type="password" class="value" placeholder="8~16자, 대소문자구분, 특수문자가능" v-model="password" @input="parentSetData('password', password)" maxLength="16"></input>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('password')" v-html="validation.firstError('password')"></div>
                </div>
                <div class="form-data">
                    <div class="title">비밀번호<br>확인</div>
                    <div class="input">
                        <input type="password" class="value" placeholder="" v-model="passwordChk" @input="parentSetData('passwordChk', passwordChk)" maxLength="16"></input>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('passwordChk')" v-html="validation.firstError('passwordChk')"></div>
                </div>
                <div class="form-data">
                    <div class="title">전화번호</div>
                    <div class="input">
                        <input type="tel" class="value" disabled="disabled" v-model="beforePhone"></input>
                    </div>
                </div>
                <div class="form-data line job">
                    <div class="title">직업</div>
                    <div class="input">
                        <input type="text" class="value" v-model="job" @input="parentSetData('job', job)"></input>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('job')" v-html="validation.firstError('job')"></div>
                </div>
                <div class="form-data line company">
                    <div class="title">소속</div>
                    <div class="input">
                        <input type="text" class="value" v-model="belong" @input="parentSetData('belong', belong)"></input>
                    </div>
                    <div class="form-caption ml55" v-if="validation.hasError('belong')" v-html="validation.firstError('belong')"></div>
                </div>
                <div class="form-data alarm">
                    <div class="title">알림받을 분야 (최대 3개까지 선택가능)</div>
                    <div class="checkbox">
                        <div  v-for="jobCate in jobCategorys" :key="`jobCate${jobCate.mjc_idx}`" class="check-data">
                            <input
                                    type="checkbox"
                                    @change="selectJobCate(jobCate.mjc_idx)"
                                    :checked="hasSelJobCate(jobCate.mjc_idx)"
                                    :disabled="disabledJobCate(jobCate.mjc_idx)"
                                    :value="jobCate.mjc_idx"
                                    :id="`jobCate${jobCate.mjc_idx}`"></input>
                            <label :for="`jobCate${jobCate.mjc_idx}`">{{jobCate.mjc_job}}</label>

                        </div>
                    </div>
                    <div class="form-caption ml0" v-if="validation.hasError('alarmJob')" v-html="validation.firstError('alarmJob')"></div>
                </div>
                <div class="form-data">
                    <div class="title">자기소개</div>
                    <div class="introduce">
                        <textarea placeholder="자기소개 입력" style="resize: none;" v-model="introduce" @input="parentSetData('introduce', introduce)"></textarea>
                    </div>
                    <div class="form-caption ml0" v-if="validation.hasError('introduce')" v-html="validation.firstError('introduce')"></div>
                </div>
            </div>
            <div class="form-check">
                <button @click="checkValidation(4)" class="full_btn register" :disabled="registerBtnDisabled">회원가입 신청</button>
                <div class="form-caption" v-show="hasError">모두 입력해주세요</div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert"
    import {mapState} from 'vuex';
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import moment from "moment";
    import elFileMixins from "@/mixins/elFileMixins";
    import util from "@/mixins/util";

    export default {
        name: "RegistMentorInputComponent",
        mixins: [VuxAlert, MemberValidator, elFileMixins],
        components: {
        },
        props: {
            setData: {
                type: Function,
                default: () => {}
            },
            changeStep: {
                type: Function,
                default: () => {}
            },
            beforePhone: {
                default: '',
            },
            runRegist: {
                default: () => {},
            },
            registerBtnDisabled: {
                default: false,
            }
        },
        data() {
            return {
                jobCategorys: [],
                hasError: false,
                pickerOptions: {
                    disabledDate(time) {
                        // return time.getTime() > Date.now();
                        return moment(time).format('YYYY-MM-DD') > moment().add(-14, 'y').format('YYYY-MM-DD');
                    },
                },
                birth: moment('1980-01-01').format('YYYY-MM-DD'),
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.getAlarmCategory();
            this.parentSetData('birth', this.birth);
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            parentSetData(type, value) {
                // this[type] = value;
                this.setData(type, value)
                if(type === 'id') {
                    this.hasIdCheck = false;
                    this.idOverlap = false;
                }
                this.hasError = false;
            },
            getAlarmCategory() {
                try {
                    this.$api.$member.getJobCategory().then(res => res.Data.Result).then(res => {
                        if(res.Info.type === 1) {
                            this.jobCategorys = res.List;
                        }

                    })
                }catch (e) {
                    console.log(e)
                }
            },
            selectJobCate(value) {
                if(this.hasSelJobCate(value)) {
                    let jobIndex = this.alarmJob.indexOf(value);
                    this.alarmJob.splice(jobIndex, 1);
                }else{
                    this.alarmJob.push(value);
                }
                this.setData('alarmJob', this.alarmJob)
            },
            hasSelJobCate(value) {
                return this.alarmJob.indexOf(value) > -1 ;
            },
            disabledJobCate(value) {
                return this.alarmJob.length >= 3 && !this.hasSelJobCate(value);
            },
            checkValidation() {
                let validatorCheck = ['id', 'name', 'email', 'birth', 'password', 'passwordChk', 'job', 'belong', 'alarmJob', 'introduce', 'files']
                this.$validate(validatorCheck).then(success => {
                    if(!success) {
                        this.hasError = true;
                        return false;
                    }
                    if(!this.hasIdCheck) {
                        this.noIdCheck = true;
                        this.hasError = true;
                        return false;
                    }
                    this.runRegist();
                })
            },
            hasImage() {
                return !util.isEmpty(this.files);
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>
