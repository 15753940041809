<template>
    <transition-group
            :enter-active-class="`animated faster slideInRight`"
            :leave-active-class="`animated faster slideOutLeft`"
    >
        <div :key="`findpw1`" v-show="step === 1" class="find_info">
            <div class="title-wrap">
                <div class="title">아이디찾기</div>
                <div class="text">회원정보에 등록된 이름과 생년월일을 입력해주세요.</div>
            </div>
            <div class="form">
                <div class="name form-data">
                    <div class="title">이름</div>
                    <input class="value" placeholder="이름입력" @input="setData('name', $event.target.value)"
                           v-model="name"></input>
                </div>

                <div class="phone form-data">
                    <div class="title">전화번호</div>
                    <input  class="value" placeholder="전화번호('-'제외,숫자만 입력)" @input="setData('phone', $event.target.value)" v-model="phone"></input>
                </div>
                
                <!-- <div class="birth form-data">
                    <div class="title">생년월일</div> -->
                    <!--<el-select class="select year" placeholder="년도">
                        <el-option>년도</el-option>
                    </el-select>
                    <el-select class="select" placeholder="월">
                        <el-option>월</el-option>
                    </el-select>
                    <el-select class="select" placeholder="일">
                        <el-option>일</el-option>
                    </el-select>-->
                    <!-- <el-date-picker
                            class="select"
                            v-model="birth"
                            type="date"
                            placeholder="생년월일"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            :clearable="false"
                            :prefix-icon="'d'"
                            @change="setData('birth', birth)"
                    >
                    </el-date-picker>
                </div> -->

            </div>
            <div class="form_caption" v-show="noId">회원정보가 존재하지 않습니다</div>
            <button class="find_btn full_btn" @click="getId()">아이디찾기</button>
        </div>

        <div :key="`findpw2`" v-show="step === 2" class="find_info">
            <div class="title-wrap">
                <div class="title">아이디찾기</div>
            </div>
            <div class="result">
                해당 정보와 일치하는 아이디 입니다
                <!--                <div class="found_id">{{foundid}}</div>-->
                <div class="found_id" v-for="id in foundid">{{id}}</div>
            </div>
            <button class="find_btn full_btn" @click="movePage('/auth')">로그인</button>
            <div @click="movePage('/auth/findpw')" class="find_pwd cursor">비밀번호찾기</div>
        </div>
    </transition-group>
</template>

<script>
    import VuxAlert from "@/mixins/vux-alert";
    import {mapGetters, mapState} from 'vuex';
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import authUtil from "@/components/auth/authUtil";
    import util from "@/mixins/util";
    import moment from "moment";


    export default {
        name: "FindIdComponent",
        mixins: [VuxAlert, MemberValidator, authUtil],
        components: {},
        props: {},
        data() {
            return {
                step: 1,
                noId: false,
                quitId: false,
                checkType: 'id',
                authKey: '',
                name: '',
                foundid: '',
                // pickerOptions: {
                //     disabledDate(time) {
                //         // return time.getTime() > Date.now();
                //         return moment(time).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD');
                //     },
                // },
                // birth: moment('1980-01-01').format('YYYY-MM-DD'),
                phone :'',
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.initConfig()

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            movePage(url) {
                this.$router.push(url);
            },
            initConfig() {
                let authConfig = this.returnAuthConfig(this.checkType);
                this.authKey = authConfig.authKey;
            },
            setData(type, value) {
                this[type] = value;
                if (type === 'name') {
                    this.name = value;
                }
                if (type === 'phone') {
                    this.phone = value;
                }

            },
            getId() {
                console.log(this.birth, this.name)
                this.$validate(['name', 'phone']).then(res => {
                    if (!res) {
                        this.noId = true;
                        return false
                    }
                    let params = {
                        mb_hp: this.phone,
                        mb_name: this.name
                    }
                    try {
                        this.$api.$auth.findId(params).then(res => res.Data.Result).then(res => {
                            if (res.Info.type != 1) {
                                // this.errorAlert(res.Info.reason);
                                this.noId = true;
                                return false;
                            }
                            // let config = res.List.mb_id;
                            /*for (let config of res.List.mb_id)
                                this.foundid = util.htmlDecode(config);*/
                            let config = res.List.mb_id
                            this.foundid = config
                            this.nextStep()
                        })
                    } catch (e) {
                        console.log(e)
                    }
                })


            },
            nextStep() {
                this.step += this.step;

            }
        }
        ,
        watch: {}
        ,
    }
</script>

<style scoped>

</style>