<template>
    <div>
        <regist-terms-check-component :key="`regist1`" v-if="step != 4" v-show="step === 1" :setData="setData" :changeStep="changeStep"></regist-terms-check-component>
        <regist-phone-component :key="`regist3`" v-if="step != 4 && step > 1" v-show="step === 2" :setData="setData" :changeStep="changeStep"></regist-phone-component>
        <regist-mentor-input-component :key="`regist4`" v-if="step != 4 && step > 1" v-show="step === 3 && type === 1" :setData="setData" :changeStep="changeStep" :beforePhone="phone" :runRegist="regist" :registerBtnDisabled="registerBtnDisabled"></regist-mentor-input-component>
        <regist-mentee-input-component :key="`regist5`" v-if="step != 4 && step > 1" v-show="step === 3 && type === 2" :setData="setData" :changeStep="changeStep" :beforePhone="phone" :runRegist="regist" :registerBtnDisabled="registerBtnDisabled"></regist-mentee-input-component>
        <div class="j_content step4 join" v-show="step === 4" >
            <div class="jc_header">
                회원가입
            </div>
            <div class="title">회원가입 완료</div>
            <span>{{name}}</span>님 회원가입을 환영합니다! <br>
            관리자 가입승인 후 이용하실 수 있습니다!
        </div>
        <!--<button @click="openModal('parental-consent-modal')">부모 동의 모달</button>-->
        <parental-consent-modal :setData="setData" :changeStep="changeStep" :menteePhone="phone" :runRegist="regist" :registerBtnDisabled="registerBtnDisabled"></parental-consent-modal>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert"
    import RegistTermsCheckComponent from "@/components/auth/RegistTermsCheckComponent";
    import RegistPhoneComponent from "@/components/auth/RegistPhoneComponent";
    import RegistMentorInputComponent from "@/components/auth/RegistMentorInputComponent";
    import RegistMenteeInputComponent from "@/components/auth/RegistMenteeInputComponent";
    import ParentalConsentModal from "@/components/modal/ParentalConsentModal";
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import util from "@/mixins/util";
    import elFileMixins from "@/mixins/elFileMixins";
    import {loginTokenSet} from "@/mixins/Auth";

    export default {
        name: "RegistComponent",
        mixins: [VuxAlert, elFileMixins, MemberValidator],
        components: {
            RegistTermsCheckComponent,
            RegistPhoneComponent,
            RegistMentorInputComponent,
            RegistMenteeInputComponent,
            ParentalConsentModal
        },
        props: {
            step: {
                type: Number,
                default: 1,
            },
            parentsChangeStep: {
                type: Function,
                default: () => {
                }
            }
        },
        data() {
            return {
                // nowStep: 1,
                nowStep: 1,
                moveBefore: false,
                registerBtnDisabled: false,
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.$router.replace(`${this.$route.path}?step=1`);
        },
        mounted() {
            EventBus.$on('beforeStep', this.beforeStep);
        },
        beforeDestroy() {
            EventBus.$off('beforeStep');
        },
        destroyed() {
        },
        computed: {},
        methods: {
            setData(type, value) {
                this[type] = value;
                if (type === 'id') {
                    this.idOverlap = false;
                }
                if (type == 'nick') {
                    this.nickOverlap = false;
                    this.hasNickChk = false;
                }
            },
            changeStep(nextStep) {
                this.$router.push(`${this.$route.path}?step=${nextStep}`);
                if(nextStep <= 1) {
                    this.initData();
                }
                // this.nowStep = nextStep;
                // this.parentsChangeStep(this.nowStep);
            },
            checkStepValue(step) {
                if(step === 4) {
                    return true;
                }
                if(step > 1 && this.type < 1) {
                    return false;
                }else if(step > 2 && this.type === 0 && util.isEmpty('phone')) {
                    return  false;
                }
                return true;
/*                if(this.type === 0) {
                    this.$router.replace(`${this.$route.path}?step=1`);
                    this.nowStep = 1;
                    this.parentsChangeStep(this.nowStep);
                }else if(util.isEmpty('phone')) {
                    this.$router.replace(`${this.$route.path}?step=2`);
                    this.nowStep = 2;
                    this.parentsChangeStep(this.nowStep);
                }*/
            },
            openModal(name) {
                this.$modal.show(name)
            },
            regist() {
                let params = {};
                if(this.type != 1) {
                    // this.menteeRegist();
                    params = this.makeMenteeParams();
                }else{
                    //this.memtorRegist();
                    params = this.makeMentorParams();
                }
                this.registerBtnDisabled = true;
                try {
                    this.$api.$auth.regist(params).then(res => res.Data.Result).then(res => {
                        let type = this.registReturnType(res.Info.type);
                        let token = res.List.token;
                        if(type != true) {
                            this.errorAlert(type);
                            this.registerBtnDisabled = false;
                            return false;
                        }
                        this.uploadFiles(this.files, 'profile', res.List.mb_no).then(res => {
                            this.registAfterFunc(token);
                        });

                    })
                }catch (e) {
                    this.registerBtnDisabled = false;
                    console.log(e)
                }
            },
            makeMentorParams() {
                return {
                    mb_type: this.type,
                    mb_id: this.id,
                    mb_password: this.password,
                    mb_password_confirmation: this.passwordChk,
                    mb_hp: this.phone,
                    mb_name: this.name,
                    mb_birth: this.birth,
                    mb_email: this.email,
                    mb_memo: this.introduce,
                    mb_job: this.job,
                    mb_belong: this.belong,
                    mjc_idx: this.alarmJob
                }
            },
            makeMenteeParams() {
                let params = {
                    mb_type: this.type,
                    mb_id: this.id,
                    mb_password: this.password,
                    mb_password_confirmation: this.passwordChk,
                    mb_hp: this.phone,
                    mb_name: this.name,
                    mb_birth: this.birth,
                    mb_email: this.email,
                    mb_memo: this.introduce,
                }
                let birthCheck = this.$moment().add(-14, 'y').format('YYYY-MM-DD')
                if(birthCheck < this.birth) {
                    params.mg_name = this.parentName;
                    params.mg_birth = this.parentBirth;
                    params.mg_hp = this.parentPhone;
                }
                return params;
            },
            registAfterFunc(token) {
                let name = this.name;
                this.initData();
                // loginTokenSet(token);
                this.name = name;
                this.registerBtnDisabled = false;
                this.changeStep(4)
            },
            registReturnType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '아이디를 입력해주세요.';
                    case -3:
                        return '비멀번호를 입력해주세요.';
                    case -4:
                        return '아이디가 중복되었습니다.';
                    case -5:
                        return '이름을 입력해주세요.';
                    case -6:
                        return '전화번호를 입력해주세요.';
                    case -7:
                        return '중복된 전화번호 입니다.';
                    case -8:
                        return '자기소개를 입력해주세요.';
                    case -9:
                        return '생년월일을 입력해주세요.';
                    case -10:
                        return '생일을 입력해주세요.';
                    case -11:
                        return '직업을 입력해주세요.';
                    case -12:
                        return '소속을 입력해주세요.';
                    case -13:
                        return '기관명을 입력해주세요.';
                    case -14:
                        return '없는 기관입니다.';
                    case -19:
                        return '보호자 이름을 입력해주세요.';
                    case -20:
                        return '보호자 생년월일을 입력해주세요.';
                    case -21:
                        return '보호자가 만 19세 미만입니다.';
                    case -22:
                        return '보호자 전화번호를 입력해주세요.';
                    case -23:
                        return '보호자 전화번호가 중복입니다.';
                    case -24:
                        return '보호자와 같은 전화번호는 불가합니다.';
                    default:
                        return '회원가입에 실패하였습니다. 잠시후 다시 시도해주세요.'
                }
            }
        },
        watch: {
            '$route.fullPath': {
                deep: true,
                handler: function(val, oldVal) {
                    this.$nextTick(() => {
                        this.nowStep = this.$route.query.hasOwnProperty('step') && !isNaN(this.$route.query.step) ? Number(this.$route.query.step) : 1;
                        if(!this.checkStepValue(this.nowStep)) {
                            this.nowStep = this.nowStep - 1;
                            this.$router.replace(`${this.$route.path}?step=${this.nowStep}`)
                        }else{
                            this.parentsChangeStep(this.nowStep);
                        }
                    })
                },
            }
        },
    }
</script>

<style scoped>

</style>
