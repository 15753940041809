<template>
    <div class="j_content step2">
        <div class="jc_header">
            회원가입
        </div>
        <div class="cert_phone">
            <div class="title-wrap">
                <div class="title">전화번호 인증</div>
            </div>
            <div class="form">
                <div class="form-data">
                    <div class="title">전화번호</div>
                    <div class="input">
                        <input type="tel" class="value" placeholder="’-’ 제외, 숫자만 입력" maxLength="11" v-model="phone" @input="parentSetData('phone', phone)"></input>
                        <button class="send" @click="sendPhoneCertify">인증번호 전송</button>
                    </div>
                    <div class="form-caption" v-if="validation.hasError('phone')">{{validation.firstError('phone')}}</div>
                    <div class="form-caption" v-else-if="phoneOverlap">중복된 전화번호 입니다.</div>
                </div>
                <div class="form-data number" v-if="phoneCertifyAuth != ''">
                    <div class="title">인증번호</div>
                    <div class="input">
                        <input class="value" placeholder="인증번호 입력" v-model="phoneCertify" @input="parentSetData('phoneCertify', phoneCertify)"></input>
                    </div>
                    <div class="form-caption" v-if="phoneCertifyFail">인증번호가 일치하지 않습니다</div>
                </div>
            </div>
            <button class="form_btn full_btn" @click="checkPhoneCertify(nextFunc)">다음</button>
            <div class=" form-caption" v-if="validation.hasError('phoneCertify') || validation.hasError('phoneCertifyAuth')">모두 입력해 주세요</div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert"
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import {mapState} from 'vuex';

    export default {
        name: "RegistPhoneComponent",
        mixins: [VuxAlert, MemberValidator],
        components: {
        },
        props: {
            setData: {
                type: Function,
                default: () => {}
            },
            changeStep: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                nowStep: 1,
                moveBefore: false,
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            parentSetData(type, value) {
                // this[type] = value;
                this.setData(type, value);
                if(type === 'phone') {
                    this.phoneCertifyAuth = '';
                }
                if(type === 'phoneCertify') {
                    this.phoneCertifyFail = false;
                }
            },
            nextFunc() {
                this.changeStep(3);
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>