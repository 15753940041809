<template>
    <transition-group
            :enter-active-class="`animated faster slideInRight`"
            :leave-active-class="`animated faster slideOutLeft`"
    >
        <div :key="`findpw1`" v-show="step === 1" class="find_info">
            <div class="title-wrap">
                <div class="title">비밀번호 찾기</div>
                <div class="text">회원정보에 등록된 이메일주소로 임시 비밀번호를 보내드립니다.</div>
            </div>
            <div class="form">
                <div class="name form-data">
                    <div class="title">아이디</div>
                    <input class="value" placeholder="아이디입력" @input="setData('id', $event.target.value)" v-model="id"></input>
                </div>
                <div class="name form-data">
                    <div class="title">이름</div>
                    <input class="value" placeholder="이름입력" @input="setData('name', $event.target.value)" v-model="name"></input>
                </div>

                <div class="phone form-data">
                    <div class="title">전화번호</div>
                    <input  class="value" placeholder="전화번호('-'제외,숫자만 입력)" @input="setData('phone', $event.target.value)" v-model="phone"></input>
                </div>
                <!-- <div class="birth form-data">
                    <div class="title">생년월일</div> -->
                    <!-- <el-select class="select year" placeholder="년도">
                         <el-option>년도</el-option>
                     </el-select>
                     <el-select class="select" placeholder="월">
                         <el-option>월</el-option>
                     </el-select>
                     <el-select class="select" placeholder="일">
                         <el-option>일</el-option>
                     </el-select>-->
                    <!-- <el-date-picker
                            class="select"
                            v-model="birth"
                            type="date"
                            placeholder="생년월일"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            :clearable="false"
                            :prefix-icon="'d'"
                            @change="setData('birth', birth)"
                    >
                    </el-date-picker>
                </div> -->
            </div>
            <div class="form_caption"  v-show="noId">정보가 일치하는 회원이 없습니다</div>
            <button class="find_btn full_btn" @click="getPw()">임시 비밀번호 발송</button>
        </div>

        <div :key="`findpw2`" v-show="step === 2" class="find_info">
            <div class="title-wrap">
                <div class="title">비밀번호 찾기</div>
            </div>
            <div class="result pwd">
                등록된 이메일주소로 임시 비밀번호를 발송하였습니다. <br>
                임시 비밀번호로 로그인 후 비밀번호를 변경해주세요.
            </div>
            <button class="find_btn full_btn" @click="movePage('/auth')">로그인</button>
            <div class="find_pwd cursor" @click="getPw(true)">임시 비밀번호 재발송</div>
        </div>
    </transition-group>
</template>

<script>
    import VuxAlert from "@/mixins/vux-alert";
    import {mapGetters, mapState} from 'vuex';
    import moment from "moment";
    import MemberValidator from "@/mixins/validators/MemberValidator";

    export default {
        name: "FindPwComponent",
        mixins: [VuxAlert,MemberValidator],
        components: {},
        props: {},
        data() {
            return {
                step: 1,
                noId: false,
                quitId: false,
                // pickerOptions: {
                //     disabledDate(time) {
                //         // return time.getTime() > Date.now();
                //         return moment(time).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD');
                //     },
                // },
                // birth: moment('1980-01-01').format('YYYY-MM-DD'),
                checkType: 'pw',
                authKey: '',
                name: '',
                id: '',
                phone :'',
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            initConfig() {
                let authConfig = this.returnAuthConfig(this.checkType);
                this.authKey = authConfig.authKey;
            },
            movePage(url) {
                this.$router.push(url);
            },
            setData(type, value) {
                this[type] = value;
                if (type === 'id') {
                    this.id = value;
                }
                if (type === 'name') {
                    this.name = value;
                }
                if (type === 'phone') {
                    this.birth = value;
                }

            },
            getPw(hasAgain) {

                this.$validate(['name','phone','id']).then(res => {
                    if (!res) {
                        this.noId = true;
                        return false
                    }
                    let params = {
                        mb_id: this.id,
                        mb_name :this.name,
                        mb_hp: this.phone,
                    }
                    try {
                        this.$api.$auth.findPw(params).then(res => res.Data.Result).then(res => {
                            if (res.Info.type != 1) {
                                // this.errorAlert(res.Info.reason);
                                this.noId = true;
                                return false;
                            }
                           console.log(res.Info.reason);
                            this.nextStep()
                            if(hasAgain === true) {
                                this.errorAlert('임시 비밀번호를 재발송하였습니다.');
                            }
                        })
                    } catch (e) {
                        console.log(e)
                    }
                })


            }, nextStep() {
                this.step =2;
            }
        },
        watch: {},
    }
</script>

<style scoped>

</style>