const configAlarm = {
    list: {
        alarmLayout: 'AlarmListLayout',
        directType: '',
    },
    detail: {
        alarmLayout: 'AlarmDetailLayout',
        directType: 'back',
    }
}

export  default {
    methods: {
        returnAlarmConfig(type) {
            return configAlarm[type];
        }
    }
}